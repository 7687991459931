import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import { VideoBanner, SliceZone } from "../components/pageComponents"
import SEO from "../components/seo"

const IndexPage = ({ data: { prismicHomePage } }) => {
  const { banner_nav, video_teaser, body } = prismicHomePage.data

  return (
    <Layout reverseHeader={true}>
      <SEO title="Home" />
      <VideoBanner teaser={video_teaser} nav={banner_nav}></VideoBanner>
      <SliceZone slices={body} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    prismicHomePage {
      data {
        title {
          text
        }
        video_teaser {
          url
        }
        banner_nav {
          page {
            uid
          }
          nav_title {
            text
          }
        }
        body {
          ... on PrismicHomePageBodyTwoColumnText {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
            }
          }
          ... on PrismicHomePageBodyStandardContent {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              link {
                uid
              }
              reverse_layout
            }
          }
          ... on PrismicHomePageBodyFeatureContent {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              link {
                uid
              }
              reverse_layout
            }
          }
          ... on PrismicHomePageBodyMembershipTeaser {
            id
            slice_type
            primary {
              heading {
                text
              }
              text {
                html
              }
              page {
                uid
              }
            }
          }
        }
      }
    }
  }
`
